import React, {
    FocusEventHandler,
    HTMLInputTypeAttribute,
    JSXElementConstructor,
    KeyboardEventHandler,
    LegacyRef,
    MouseEventHandler,
    ReactComponentElement,
    ReactElement,
    Ref,
    useEffect,
    useState,
} from "react";

import dayjs from "dayjs";
import customParse from "dayjs/plugin/customParseFormat";

import { useId } from "react-aria";

import { type PropsOf, type Theme, css } from "@emotion/react";
import styled from "@emotion/styled";

import colors from "../../../../utils/colors";
import { getValidationErrorMessage } from "../../../../utils/validation/errorMessages";
import Validator, {
    Validation,
    ValidationStr,
} from "../../../../utils/validation/Validator";
import Icon, { type PredefinedIconTypeStr } from "../../icons/Icon";
import { type PredefinedIconType } from "../../icons/Icon/Predefined";
import Button, { type ButtonColorStr } from "../../NewForms/Button";
import { withField as withFieldWrapper } from "../Field";
import FormError from "../FormError";

dayjs.extend(customParse);

const validator = new Validator();

const Container = styled.div`
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const Placeholder = styled.label`
    position: absolute;
    right: 11px;
    left: 11px;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 1rem;
    color: ${colors.darkGrey};
    pointer-events: none;
    transition: 0.2s ease all;
`;

export const inputStyles = ({ theme }: { theme: Theme }) => css`
    width: 100%;
    padding: 15px 10px 5px;
    overflow: hidden;
    font-size: 1em;
    line-height: 20px;
    color: ${theme.colors.blue.toString()};
    border-color: ${theme.colors.darkGrey.toString()};
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;

    &:placeholder-shown {
        font-weight: normal;
    }

    &:placeholder-shown ~ ${Placeholder} {
        top: 0;
    }

    &:not(:placeholder-shown) ~ ${Placeholder}, &:focus ~ ${Placeholder} {
        top: 2px;
        display: block;
        align-items: flex-start;
        height: 1.5em;
        overflow-x: hidden;
        font-size: 0.75em;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

const textareaStyles = css`
    height: 100%;
    min-height: 120px;
`;

const textareaPlaceholderStyles = css`
    align-items: flex-start;
`;

export const InputButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px !important;
    padding: 0 !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;

export type BaseInputProps<
    C extends keyof JSX.IntrinsicElements | JSXElementConstructor<any> = "input"
> = {
    hasError?: boolean;
    Component?: C;
} & PropsOf<C>;

const BaseInput = <
    C extends keyof JSX.IntrinsicElements | JSXElementConstructor<any> = "input"
>({
    hasError: _,
    Component = "input",
    ...props
}: BaseInputProps<C>): ReactElement<any, any> | null => (
    <Component
        {...(props.innerRef ? { ref: props.innerRef } : {})}
        {...props}
    />
);

const StyledInput = styled(BaseInput)`
    ${inputStyles}

    border-color: ${({ hasError }) =>
        hasError ? colors.red : colors.darkGrey};
`;

const StyledTextarea = styled(StyledInput)`
    ${textareaStyles}
`;

export type InputProps<C extends "input" | "textarea"> = {
    handleClick?: MouseEventHandler<HTMLButtonElement>;
    buttonIcon?: PredefinedIconType | PredefinedIconTypeStr;
    buttonColor?: ButtonColorStr;
    validation?: Validation | ValidationStr;
    fullWidth?: boolean;
    fullHeight?: boolean;
    errorClassName?: string;
    isDisabled?: boolean;
    onError?: () => void;
    onErrorSolved?: () => void;
    forceError?: boolean;
    required?: boolean;
    withField?: boolean;
    maxWidth?: number;
    buttonIconGtag?: string;
} & (C extends "input"
    ? {
          type?: HTMLInputTypeAttribute;
          innerRef?: Ref<HTMLInputElement> | LegacyRef<HTMLInputElement>;
      } & Omit<JSX.IntrinsicElements["input"], "ref" | "type">
    : {
          type: C;
          innerRef?: Ref<HTMLTextAreaElement> | LegacyRef<HTMLTextAreaElement>;
      } & Omit<JSX.IntrinsicElements["textarea"], "ref" | "type">);

const Input = <C extends "input" | "textarea">({
    value,
    onBlur,
    onKeyUp,
    placeholder,
    name,
    handleClick,
    buttonIcon = "search",
    buttonColor = "red",
    validation,
    fullWidth = false,
    fullHeight = false,
    errorClassName,
    type = "text",
    id,
    isDisabled = false,
    onError,
    onErrorSolved,
    forceError = false,
    required = false,
    withField = false,
    maxWidth,
    buttonIconGtag,
    innerRef,
    ...props
}: InputProps<C>): ReactElement<any, any> | null => {
    const [hasError, setHasError] = useState(false);
    const [validateOnKeyUp, setValidateOnKeyUp] = useState(false);

    useEffect(() => {
        if (forceError === true) {
            setHasError(true);
        }
    }, [forceError]);

    /**
     * Get the corresponding validator.
     */
    const isValid =
        validation == null
            ? () => true
            : validator.getValidator(validation, value, required);

    /**
     * If we have a value while we focus the field, start validation on key up.
     * use toString() here otherwise, if the value is a number, it returns undefined and skips validation.
     */
    const onFocus: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = () => {
        if (value && value.toString().length > 0) {
            setValidateOnKeyUp(true);
        }
    };

    /**
     * If we should validate on key up or we have an error, validate.
     */
    const localOnKeyUp: KeyboardEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (evt) => {
        onKeyUp?.(evt);

        if (validateOnKeyUp || hasError) {
            if (isValid()) {
                setHasError(false);
                onErrorSolved?.();
            } else {
                setHasError(true);
                onError?.();
            }
        }
    };

    /**
     * Returns the error message for the validation type.
     */
    const getErrorMessage = () => getValidationErrorMessage(validation);

    /**
     * When we leave the input.
     */
    const localOnBlur: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (evt) => {
        if (isValid()) {
            setHasError(false);
            onErrorSolved?.();
        } else {
            setHasError(true);
            onError?.();
        }

        onBlur?.(evt);
    };

    const fallbackId = useId();
    id ??= name ?? fallbackId;

    if (type === "textarea") {
        const component = (
            <Container
                css={css`
                    ${fullWidth ? "width: 100%;" : ""}
                    ${fullHeight ? "height: 100%;" : ""}
                `}
            >
                <StyledTextarea
                    Component="textarea"
                    placeholder={" "}
                    onKeyUp={localOnKeyUp}
                    onBlur={localOnBlur}
                    {...{
                        id,
                        name,
                        value,
                        onFocus,
                        required,
                        ...props,
                    }}
                />
                <Placeholder htmlFor={id} css={textareaPlaceholderStyles}>
                    {placeholder}
                </Placeholder>
            </Container>
        );

        return withField ? withFieldWrapper(component) : component;
    }

    const component = (
        <>
            <Container
                css={css`
                    max-width: ${maxWidth ?? "none"};
                    ${fullWidth ? "width: 100%;" : ""}
                `}
            >
                <StyledInput
                    placeholder=" "
                    onKeyUp={localOnKeyUp}
                    onBlur={localOnBlur}
                    autoComplete={
                        [
                            "zipcode",
                            "consumption",
                            "street",
                            "houseNumber",
                            "streetSelectList",
                            "placeSelectList",
                            "houseNumber",
                            "ownerName",
                            "ownerStreet",
                            "ownerStreetNumber",
                            "ownerZipcode",
                            "ownerCity",
                        ].includes(name)
                            ? "off"
                            : "on"
                    }
                    disabled={isDisabled}
                    {...{ id, name, value, type, onFocus, ...props }}
                />
                <Placeholder htmlFor={id}>{placeholder}</Placeholder>
                {handleClick && (
                    <InputButton
                        onClick={handleClick}
                        color={buttonColor}
                        data-gtag={buttonIconGtag}
                    >
                        <Icon<any> iconName={buttonIcon} />
                    </InputButton>
                )}
            </Container>
            {hasError && (
                <FormError
                    className={errorClassName}
                    gtag={`error:${name}`}
                    message={getErrorMessage()}
                />
            )}
        </>
    );

    return withField ? withFieldWrapper(component) : component;
};
export default Input;
